export const addEnhancementVisuals = [
  {
    name: "Demo",
    markdown: `![Add enhancement GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addEnhancement = {
  order: 20,
  name: "Add property enhancement",
  keywords: "capex renovation",
  subcategory: "Properties",
  markdown: `# Add property enhancement

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  In Tower Hunt, property enhancements represent any upgraded physical attribute relating to a part of a property. Examples include destination dispatch elevators, internal staircases, solar installations, advertising installations, and smart windows. Enhancements are one factor used to determine the relative physical quality of a part of a property. You can add enhancements to nearly every part of the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.

  ## What to expect

  A newly created enhancement just represents *some* upgrade at a {% inlineRouterLink articleId="what-is-a-property?" %}property{% /inlineRouterLink %}. Context can be added via the {% inlineRouterLink %}datapoint{% /inlineRouterLink %} types below, each of which is {% inlineAppIcon iconName="private" /%}{% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} by default:

  - A {% inlineRouterLink %}name{% /inlineRouterLink %} that describes the enhancement
  - {% inlineRouterLink %}Milestone dates{% /inlineRouterLink %} govern the status of the upgrade and when it exists

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Property selector" %}property{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addEnhancement" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more diagram parts to place the enhancement on. Selecting multiple parts will link the enhancement to all of those parts.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. An {% inlineAppIcon iconName="sparkles" /%} enhancement appears on the selected diagram parts.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$addEnhancementVisuals /%}
  `,
};
